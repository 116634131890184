







































































import { Component, Vue } from 'vue-property-decorator'
import AppNavDrawer from '@/components/App/AppNavDrawer.vue'
import axiosInstance from '@/plugins/axios'
import Swal from 'sweetalert2'

@Component({
  components: {
    AppNavDrawer
  }
})
export default class UserEdit extends Vue {
  roles = ['STAFF', 'ADMIN']

  user = {
    username: null,
    name: null,
    phone: null,
    password: null,
    roles: []
  }

  created () : void {
    this.fetchUser()
  }

  async fetchUser (): Promise<void> {
    try {
      this.$store.dispatch('app/setIsLoading', true)
      // TODO: fetch user
      this.$store.dispatch('app/setIsLoading', false)
    } catch (error) {
      console.error(error)
      this.$store.dispatch('app/setIsLoading', false)
    }
  }

  async saveUser (): Promise<void> {
    try {
      this.$store.dispatch('app/setIsLoading', true)
      const res = await axiosInstance.post('', {}) // TODO: save user
      const data: any = res.data
      console.log('SAVE_USER_RESULT', data)
      this.$store.dispatch('app/setIsLoading', false)

      if (data.result !== 'OK') {
        Swal.fire({
          icon: 'error',
          title: data.message
        })
        return
      }

      Swal.fire({
        icon: 'success',
        title: 'ບັນທຶກສຳເລັດ'
      })
    } catch (error) {
      console.error(error)
      this.$store.dispatch('app/setIsLoading', false)

      Swal.fire({
        icon: 'error',
        title: 'ກະລຸນາລອງໃໝ່ອີກຄັ້ງ'
      })
    }
  }
}
